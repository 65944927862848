/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.css';

html {
  scroll-behavior: smooth;
}

:root {
  --font-color-dark: #41403e;
  --font-color: #ffffff;
  --active-link: #f6b96c;
  --button-color: #8e6ff7;
  --input-background: #f6f5f6;
  --background-color: #ffffff;
  --highlight-color: #fffaf0;
  --header-background: #140d4a;
  --alert-color: #ff7e7e;
}

body {
  background-color: #f7f7f7 !important;
  font-family: 'Poppins', sans-serif;
}

.modal-crear-trabajador .modal-dialog {
  max-width: 610px;
}

.modal-crear-miembro-org .modal-dialog {
  max-width: 574px;
}

.modal-selecionar-punto .modal-dialog {
  max-width: 404px;
}

.modal-cambiar-contrasena .modal-dialog {
  max-width: 423px;
}

.modal-filtro .modal-dialog {
  max-width: 468px;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.align-center {
  display: flex;
  align-items: center;
}

input,
select {
  padding: 0 10px;
  border-radius: 7px;
  border: none;
  background-color: var(--input-background);
}

.purple-button {
  border-radius: 7px;
  background-color: var(--button-color);
  color: #fff;
  border: none;
  height: 42px;
  cursor: pointer;
}

.volver {
  display: flex;
  column-gap: 5px;
  height: 42px;
  align-items: center;
  background-color: #fff;
  padding: 0 20px;
}

.cursorPointer {
  cursor: pointer;
}

.rounded {
  border-radius: 8px;
}

/* html, body { height: 100%; } */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.banner-home .carousel-inner {
  height: 189px !important;
  max-height: 189px !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.inactive {
  display: none !important;
}

.container-discount {
  text-align: left;
  float: left;
  background: #8e6ff7;
  border-radius: 100px;
  padding: 2%;
  color: #fff;
  font-weight: lighter;
}

.indicator {
  border-radius: 100px;
  padding: 2%;
  font-weight: lighter;
  font-size: 0.9rem;
}

.indicator-on-order {
  color: #F9A153;
}

.indicator-bio {
  color: #28A745;
}

.align-img-product-indicator {
  margin-left: -7px;
}

.separator-indicators {
  margin-bottom: 3px;
}

.discount-indicator {
  position: absolute;
  top: 31px;
  right: 10px;
}

.color-previous-price {
  color: red !important;
}

.previous-line {
  text-decoration: line-through !important;
}